import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface Iapp {
  icon: string;
  title: string;
  description: string;
  url: string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Ferramentas do dia-a-dia';


  apps: Iapp[] = [];


  constructor(
    private http: HttpClient
    ) {

  this.getJSON().subscribe(data => this.apps = data, error => console.log(error));
  }

  public getJSON(): Observable<any> {
    return this.http.get('/assets/apps.json')
  }

}
